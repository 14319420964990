import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { ReactComponent as SpryLogo } from '../../../imgs/spry-net.svg';

export default function Brand() {
  return (
    <BrandLink to="/">
      <SpryLogo />
      <h5>YOUR PARTNER FOR COMPUTER NETWORK SUPPORT</h5>
    </BrandLink>
  )
}

const BrandLink = styled(Link)
`
  width: 100%;
  margin: auto 0;
  display: grid;
  grid-gap: 1vh;

  h5 {
    display: none;
  }

  @media(min-width:769px){
    width: 80%;

    h5{
      display: initial;
      justify-self: center;
      text-transform: uppercase;
      color: #e8cf3f;
      text-shadow: 2px 2px 6px black;
      font-size: 1.2rem;
    }
  }

  @media(min-width:1281px){
    h5 {
      font-size: 2rem;
    }
  }

  @media(min-width:1441px){
    // width: 30%;
  }


`