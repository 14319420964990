import React from 'react'
import styled from "styled-components";
import { Link } from 'react-router-dom';
import { useSpring, animated, config } from "react-spring";
import Brand from "./brand";
import Burger from "./burger";
import Collapse from "./collapse";

export default function Nav({ navbarState, handleNavbar }) {
  const barAnimation = useSpring({ from: { transform: 'translate3d(0, -10rem, 0)' }, transform: 'translate3d(0, 0, 0)', });

  const linkAnimation = useSpring({ from: { transform: 'translate3d(0, 30px, 0)', opacity: 0 }, to: { transform: 'translate3d(0, 0, 0)', opacity: 1 }, delay: 800, config: config.wobbly, });

  return (
    <React.Fragment>
      <NavBar style={barAnimation}>
        <GridContainer className= "container">
          <BRAND />
          <NavContainer>
            <NavLinks style={linkAnimation}>
              <Link to="/">Home</Link>
              <Link to="/services">Services</Link>
              <Link to="/support">Support</Link>
              <Link to="/contact">Contact Us</Link>
            </NavLinks>
            <BurgerWrapper>
              <Burger
                navbarState={navbarState}
                handleNavbar={handleNavbar}
                />
            </BurgerWrapper>
          </NavContainer>
        </GridContainer>
      </NavBar>
      <Collapse
        navbarState={navbarState}
        handleNavbar={ handleNavbar }
        />
  </React.Fragment >
  )
}


const NavBar = styled(animated.nav)
`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background: #2d3436;
  background-size:cover;
  z-index: 1;
  font-size: 1.4rem;
`;

const GridContainer = styled.div `
  display: grid;
  grid-template: "brand links" minmax(min-content, 10vh) / 1fr minmax(min-content, 1fr);
  grid-gap: 1em;
`;

const BRAND = styled(Brand)
`
  grid-area: brand;
`

const NavContainer = styled.div `
  grid-area: links;
  justify-self: end;
  align-self: center;
`

const NavLinks = styled(animated.ul)
`
  align-self: center;
  justify-self: center;

  & a {
    color: #efefef;
    text-transform: uppercase;
    font-weight: 600;
    border-bottom: 1px solid transparent;
    margin: 0 1.5rem;
    transition: all 300ms linear 0s;
    text-decoration: none;
    cursor: pointer;

    &:hover {
      color: #e8cf3f;
      border-bottom: 1px solid #fdcb6e;
    }

    @media (max-width: 768px) {
      display: none;
    }
  }
`;

const BurgerWrapper = styled.div `
  margin: auto 0;

  @media (min-width: 769px) {
    display: none;
  }
`;