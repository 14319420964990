import { createGlobalStyle } from 'styled-components';

const GlobalStyles = createGlobalStyle `

  *,
  *::after,
  *::before {
    margin: 0px;
    padding: 0px;
    box-sizing: border-box;
  }

  html {
    font-size: 62.5%;
  }

  body {
    background-color: #e2e2e2;
    box-sizing: border-box;
    font-family: 'Montserrat', sans-serif;
  }

  a {
  text-decoration: none;
  }

  li {
    list-style-type: none;
  }

  .blank {
    grid-area: blank;
    height: 15vh;
  }

  .headerTitles {
    grid-area: title;
    font-size: 3em;
    justify-self: center;
    text-transform: uppercase;
  }

  .logo {
    fill: #e2e2e2;
    width: 100%;
  }

  .container {
    width: 85vw;
    margin: auto;

    @media(min-width: 769px) {
      width: 90vw;
    }
  }

`;

export default GlobalStyles;

export const size = {
  mobileS: '320px',
  mobileM: '375px',
  mobileL: '425px',
  tablet: '768px',
  laptop: '1024px',
  laptopL: '1440px',
  desktop: '2560px'
}

export const device = {
  mobileS: `(min-width: ${size.mobileS})`,
  mobileM: `(min-width: ${size.mobileM})`,
  mobileL: `(min-width: ${size.mobileL})`,
  tablet: `(min-width: ${size.tablet})`,
  laptop: `(min-width: ${size.laptop})`,
  laptopL: `(min-width: ${size.laptopL})`,
  desktop: `(min-width: ${size.desktop})`,
  desktopL: `(min-width: ${size.desktop})`
};