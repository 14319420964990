import { useState } from 'react';
import Joi from 'joi-browser';

export const useInput = (initialVal, name, schema) => {
  const [value, setValue] = useState(initialVal);
  const [error, setError] = useState(null)

  const validateProperty = ({ name, value }) => {
    const obj = {
      [name]: value
    };
    const { error } = Joi.validate(obj, schema);
    setError(error ? error.details[0].message : null);
  }

  return {
    value,
    setValue,
    error,
    reset: () => setValue(initialVal),
    bind: {
      value,
      name,
      onChange: ({ target, currentTarget: input }) => {
        validateProperty(input);
        setValue(target.value)
      }
    }
  };
};
