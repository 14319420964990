import React from 'react';
import styled from 'styled-components';

export default function Services() {

  return (
    <Serv>
      <h1>Services</h1>
      <Wrapper>
        {content.map((header, index)=>{
          return (
            <Section key={header}tiles={header[1].length}>
              <h2 key={index}>{header[0]}:</h2>
              <ul key={header[0]}>
                {header[1].map((content, tile) => {
                  return <li key={tile}>{content}</li>
                })}
              </ul>
            </Section>
          );
        })}
      </Wrapper>
    </Serv>

  )

}

const Serv = styled.div `
  display: grid;
  grid-template:
    'services' auto
    'wrapper' auto/auto;
  grid-gap: 15px;
  justify-items: center;

  h1 {
    grid-area: services;
    font-size: 4em;
  }
`

const Wrapper = styled.div `
grid-area: wrapper;
width: 100vw;
`

const Section = styled.div `
display: grid;
grid-template: "header" auto "cards" auto /auto;
margin-bottom: 50px;


h2{
  grid-area: header;
  justify-self: center;
  font-size: 2em;
  margin: 10px auto;
}

ul{
  width: 95vw;
  grid-area: cards;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  grid-auto-rows: 250px;
  grid-gap: 15px;
  justify-content: center;
  justify-self: center;
}

li{
  display:grid;
  justify-content: center;
  align-content: center;
  font-size: 1.5em;
  background-color: #535559ba;
  color: #e2e2e2;
  border-radius: 5px;
  box-shadow: #00000033 0px 4px 8px;
}

li:hover {
    box-shadow: #00000033 0px 8px 16px;
  }

`

const ongoingSupport = ['System Matinence',
  'Upgrades',
  'Patch & Upgrade Management'
];

const security = ['Firewall',
  'VPN',
  'Security Audits',
  'Virus & Malware Remediation',
  'Protocol Analysis'
];

const network = ['Design',
  'Implementation',
  'Standardization',
  'Configuration',
  'Optimization',
  'Troubleshooting'
];

const advancedNetwork = ['Bandwidth Analysis',
  'Cloud Assistance',
  'Hybrid Solutions',
  'Wide Area Network Solutions',
  'System Migration',
  'Telecommuting Solutions'
];

const content = [
  ['Ongoing Support', ongoingSupport],
  ['Network', network],
  ['Advanced Network', advancedNetwork],
  ['Security', security]
]
