import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

const NotFound = (props) => {

  return (
    <NOTFOUND>
      <h1>404 Page Not Found</h1>
      <Link to="/">
        Click here to return to the Home page
      </Link>

    </NOTFOUND>
  )
}

export default NotFound;

const NOTFOUND = styled.div `
  display: grid;
  align-content: center;
  justify-content: center;
  height: 80vh;
  width: 90vw;
  margin: auto;

  h1{
    font-size: 5rem;
    justify-self: center;
  }

  a{
    font-size: 3rem;
    color: #efefef;
    padding: 5px;
    border-radius: 10px;
    background-color: #2D3336;

    &:hover{
      color: #e8cf3f;
    }
  }
`