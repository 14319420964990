import React from 'react';
import styled from 'styled-components';
import { Styledp } from './aboutUs';
import philosophy from '../../imgs/office.jpeg';

const philosophyText = "Spry-Net Systems, Inc is the technological partner to small to midsize companies. Our goal to provide the highest levels of technological support and ongoing services allowing our clients to focus on their core business.";

export default function Philosophy() {

  return (
    <Office>
        <OfficeFill>
          <PHILOSOPHY className="container">
            <h2 className="headerTitles">Our Philosophy</h2>
            <Styledp grid="text">{philosophyText}</Styledp>
          </PHILOSOPHY>
        </OfficeFill>
      </Office>
  )

}

const PHILOSOPHY = styled.div `
  display: grid;
  grid-template: "title" auto "text" auto /auto;
  grid-row-gap: 2vh;
  align-content: center;
  justify-content: center;
  height: 100%;
`

const Office = styled.div `
  background: url(${philosophy}) center no-repeat;
  background-size:cover;
  box-shadow: #444444 0px 0px 8px 0px;
`

const OfficeFill = styled.div `
  height: 100%;
  background-color: #ffffffbd;
`