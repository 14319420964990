import React from 'react';
import styled from 'styled-components';

export default function Support() {

  return (
    <Sup>
      <h1>Support Links</h1>
      <ul>
        {links.map((link, key)=> {
          return <li key={key}><a href={link[1]} target='_blank' rel="noopener noreferrer">{link[0]}</a></li>
        })}
      </ul>
    </Sup>
  )

}

const links = [
  ['Zoho Assist', 'https://assist.zoho.com/portal/sprynet'],
  ['Unattended', 'https://assist.zoho.com/urs/a6a3411636513386f8c403e9934016c1d1836412ebb4a0a77068154ae182bcfc']
]

const Sup = styled.div `
  min-height: 75vh;
  width: 65%;
  margin: 0 auto;
  display: grid;
  grid-template: "title" min-content "links" auto/ auto;
  grid-gap: 2vh;

  h1 {
    grid-area: title;
    font-size: 2em;
  }

  ul {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    grid-auto-rows: 150px;
    align-items: center;
    justify-items: center;
  }

  a {
    background-color: #386578;
    font-size: 2em;
    color: #e2e2e2;
    padding: 4rem;
    border-radius: 0.4em;
  }
`