import React from 'react';
import styled from 'styled-components';

export default function Footer() {
  return (
    <Foot>
      <div>Copyright &copy; {new Date().getFullYear()} Spry-Net Systems</div>
      <div>Site designed/developed by Anthony Fernando</div>
    </Foot>
  )
}

const Foot = styled.div `
  display: grid;
  height: 10vh;
  justify-content: center;
  aligh-content: center;
  background-color: #2D3336;

  div {
    text-align: center;
    color: #e8cf3f;
    font-size: 1.2em;
  }

  div:first-child {
    align-self: end;
  }
`