import React from 'react';
import server from '../../imgs/server.jpg';
import styled from 'styled-components';

const about1 = "Spry-Net Systems Inc. is a San Diego Based network consulting company offering a full range of network systems integration and support services. We can design your network, install all components and provide ongoing support services. We support a variety of environments from Novell 3.x-5.x, NT 5.x, Windows 200x, Windows XP, Windows 7, Windows 8, Windows 10, as well as, cross platform networking that may include Mac 8.x-10.x and legacy systems such as AS400, VAX or other main frame technologies."

const about2 = "Our Systems engineers can also support intranet/internet security requirements, VPN access, Wide Area Networking and a variety of telecommuting solutions. We can analyze your digital workflow to determine how to make optimal use of your network resources, streamline your protocol utilization and identify bottlenecks in your technology that may hinder your efficiencies. We work with you to understand your needs and determine the technologies that best fits your requirements today and provide a path to grow with your business. We strive to be your technology partner to identify, implement and support the technologies that help you do your job more efficiently.";

export default function AboutUs() {

  return (
    <ABOUTUS className="container">
      <h2 className = "headerTitles " > About Us </h2>
      <IMG src = { server } alt = ""/>
      <Styledp grid = "text1">{about1}</Styledp>
      <Styledp grid = "text2">{ about2 }</Styledp>
    </ABOUTUS>
  )

}

const ABOUTUS = styled.div `
  display: grid;
  grid-template: "title" min-content "img" 25vh "text1" min-content "text2" min-content / auto;
  grid-gap: 3em 1em;
  height: 100%;

  @media(min-width: 769px) {
    grid-template: "title" min-content "text1" min-content "img" 25vh "text2" min-content / auto;
    grid-gap: 4em;
  }

  @media(min-width: 1025px) {
    grid-template: "title title" min-content "img text1" min-content "img text2" min-content / 1fr 1fr;
  }
`

export const Styledp = styled.p `
  grid-area: ${props => props.grid};
  font-size: 1.8em;
  line-height: 2;
`

export const IMG = styled.img `
  grid-area: img;
  height: 100%;
  width: 100%;
  border-radius: 0.5em;
  object-fit:cover;
`