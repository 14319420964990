import React, { useState } from 'react'
import Recaptcha from 'react-google-recaptcha';
import Joi from "joi-browser";
import axios from 'axios';
import styled from 'styled-components';
import { useInput } from '../components/common/useInput';

const siteKey = "6LfaoucUAAAAABsUydkrMjPyrZDiDrccjyMHqurw";

const Contact = (props) => {
  const { value: name, bind: nameBind, error: nameError } = useInput('', "name", { name: Joi.string().required().label("Name") });
  const { value: email, bind: emailBind, error: emailError } = useInput('', "email", { email: Joi.string().email().required().label("Email Address") });
  const { value: message, bind: messageBind, error: messageError } = useInput('', "message", { message: Joi.string().required().label("Message") });
  const [submitable, setSubmitable] = useState(false);

  const handleRecaptcha = () => setSubmitable(true);

  const handleSubmit = e => {
    e.preventDefault();
    if ((name && email && message) && (!nameError && !emailError && !messageError)) {
      axios.post("http://192.168.105.80/api/contact", { name, from: email, message }, { headers: { 'Content-Type': 'application/json' }, Accept: 'application/json' });
      // axios POST to backend
      if (submitable) {
        // move content above in here when done testing
      }
    }

  }

  return (
    <ContactGrid className="container">
      <h2 className="headerTitles">Contact Us</h2>
      <Info>
        <div className="phone">
          <p>(619)470-7100</p>
        </div>
        <div>
          <p>2517 Windard Way</p><p>Chula Vista, CA 91914</p>
        </div>
      </Info>
      <FormGrid onSubmit={handleSubmit} >
        <label htmlFor="name">Name:</label>
        <input {...nameBind} />
        {nameError && <div className="inputError">{nameError}</div>}
        <label htmlFor="email">Email:</label>
        <input {...emailBind} />
        {emailError && <div className="inputError">{emailError}</div>}
        <label htmlFor="message">Message:</label>
        <textarea {...messageBind} />
        {messageError && <div className="inputError">{messageError}</div>}
        <Recaptcha sitekey = {siteKey} onChange = {handleRecaptcha} />
        <button>Submit</button>
      </FormGrid>
    </ContactGrid>
  )
}

export default Contact;

const ContactGrid = styled.div `
  display: grid;
  grid-template: "title" min-content "info" min-content "form" min-content;
  grid-gap: 4rem;
  padding-bottom: 4rem;

  @media (min-width: 769px) {
    grid-template: "title title" min-content "form info" min-content / 2fr 1fr;
  }
`

const Info = styled.div `
  grid-area: info;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 4rem;

  .phone {
    justify-self: end;
  }

  div {
    font-size: 1.5rem;
    align-self: center;
  }
`

const FormGrid = styled.form `
  display: grid;
  grid-auto-rows: min-content;
  grid-gap: 4rem;

  label, input, textarea, button, .inputError {
    font-size: 2.5rem;
  }

  .inputError {
    background-color: red;
  }

  textarea {
    height: 15vh;
    resize: vertical;
  }

  button{
    color: #e8cf3f;
    background-color: #2d3436;
    border-color: #2d3436;
    border-radius: 1rem;
    width: min-content;
    height: min-content;
    padding: 0 1.5rem;
  }
`
