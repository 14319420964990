import React from 'react';
import styled from 'styled-components';
import AboutUs from '../components/home/aboutUs';
import Philosophy from '../components/home/philosophy';
import Contact from './contact';

export default function Home() {
  return (
    <HOME>
      <AboutUs/>
      <Philosophy/>
      <Contact/>
    </HOME>
  )
}

const HOME = styled.div `
  display: grid;
  grid-template: "aboutus" auto "philosophy" 90vh / auto;
  grid-gap: 10vh;
`