import React, { useState } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import Nav from './components/common/nav/nav';
import Footer from './components/common/footer/footer';
import Home from './pages/home';
import Services from './pages/services';
import Support from './pages/support';
import Contact from './pages/contact';
import NotFound from './pages/notFound';
import ScrollToTop from './services/scrollTop';
import GlobalStyles from './styles/Global';

function App() {
  const [isNavbarOpen, setNavBarOpen] = useState(false);

  const handleNavBar = () => { setNavBarOpen(!isNavbarOpen); }

  return (
    <React.Fragment>
        <Nav
          navbarState = {isNavbarOpen}
          handleNavbar = {handleNavBar}
          />
        <div className="blank" />
        <Switch>
          <Route path="/not-found" component= {NotFound} />
          <Route path="/support" exact component= {Support} />
          <Route path="/services" component= {Services} />
          <Route path="/contact" component= {Contact} />
          <Route path="/" exact component= {Home} />
          <Redirect to="/not-found" />
        </Switch>
        <Footer/>
        <ScrollToTop />
        <GlobalStyles/>
    </React.Fragment>
  );
}

export default App;
